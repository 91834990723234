const dummyPeople = [{
    'id': '017cb40b-5240-41da-8292-7d71b7ceb275',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Rafael Lambert'
},
{
    'id': '05dd6dbb-0936-41c5-8672-482f7e50feb0',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Eléonore Renaud'
},
{
    'id': '0c849b2d-26f4-4c68-a5ac-e12bfa48d248',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Jacob Poth'
},
{
    'id': '11c2ba33-da33-43b6-803c-0066db879fb0',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Jayden Lam'
},
{
    'id': '15aa1833-0626-4c15-836b-55aad37a2739',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Tina Damjanović'
},
{
    'id': '16263cbd-99b4-42d6-a524-190b2ac932da',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Ulita Lukach'
},
{
    'id': '176197f7-3310-4a5a-bc65-8a52a3451467',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Gabriel Molina'
},
{
    'id': '29d08a11-5544-4154-82db-f0c5d7208ce5',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Anthony Taylor'
},
{
    'id': '2ba85fb7-0bcc-4610-ba99-6f2ebce43372',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Eddie Wallace'
},
{
    'id': '3048ef2b-13dd-46b6-9467-53af74e4df4e',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Laura Hansen'
},
{
    'id': '34a61fac-4c78-4f0e-a7c1-be607b231149',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Félix Pascual'
},
{
    'id': '3c05a9cf-52d8-456b-8c97-3defc1151250',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Yolanda Montero'
},
{
    'id': '433f8f7c-cc3e-4dcf-8b8e-dcabcf204255',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Dan Wallace'
},
{
    'id': '479f503f-9f9e-4a68-a25c-375e8a98b2c9',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Florence Cox'
},
{
    'id': '5ece55f5-9cdc-465e-ba3e-09d6ce0b529d',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Antero Souza'
},
{
    'id': '6ad0b8e4-f841-432d-a888-4dae2176b831',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Maeva Singh'
},
{
    'id': '7432a5d5-23b1-4adc-8e32-02ab1ac780dc',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Girindra Sheikh'
},
{
    'id': '75eea3f2-aa22-429b-9f06-9d1ef6b06bdd',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Sofia Prieto'
},
{
    'id': '788fddcb-7a24-419f-b88d-209803a883ff',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Janusz Hintze'
},
{
    'id': '83fd6083-846f-480d-b9f9-44e1831364b9',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Perry Cook'
},
{
    'id': '84953464-6fff-4447-bf07-453dcb54f919',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Mia Petit'
},
{
    'id': '84ca57b0-05ec-45e0-bde3-eb47539a504e',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Rosa Peña'
},
{
    'id': '8c56fc4f-1675-4e21-b825-21a4e2e3c74e',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Eli Walker'
},
{
    'id': '8fc3fcf7-31ac-4c1f-9028-933b87c69c20',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Zoé Lopez'
},
{
    'id': '92c4bbbe-a71a-45ae-9ee4-f2e2c630b266',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Aaliyah Jones'
},
{
    'id': 'a2a3f506-c2c5-4af4-a72d-c2c4b452d35a',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Madhav Bal'
},
{
    'id': 'b49548d5-1d9e-4550-83df-4f2aaf351fd1',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Caterina Schulte'
},
{
    'id': 'b6bb942f-dadd-43b6-890c-188ea5ea6466',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Galina Bjelica'
},
{
    'id': 'bee7add3-9de7-47e9-a3b8-526c7e81bdbc',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Brooke Singh'
},
{
    'id': 'c0119b82-c616-473d-aab3-9f1e301afc35',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Kaya Tüzün'
},
{
    'id': 'c714cb29-b905-4a26-a56a-2431acd0f417',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Franklin Austin'
},
{
    'id': 'cdd066f5-68d8-4d12-940f-86bb1e12a36b',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Johan Gerard'
},
{
    'id': 'ce94d86d-1b7c-479c-a7b2-f473271c323f',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Leonel Palomo'
},
{
    'id': 'd9842689-39a2-4cc4-9861-1e697dd5ea63',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Fatma Kutlay'
},
{
    'id': 'ead0d7bc-6668-4bca-8e9d-044f5cb896a3',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Necati Yılmaz'
},
{
    'id': 'f10feffc-ed98-4ebc-878d-b4f2d51ec33f',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Emilia Salmela'
},
{
    'id': 'f92a4e21-4a2b-4359-8aff-6545a288fa45',
    'tid': '55047195-959f-47c0-9e13-948c7c6b0bfd',
    'displayName': 'Jonathan Sørensen'
}];

const meetings = [
    { id: crypto.randomUUID(), subject: "Introduction to New Features", duration: 45 },
    { id: crypto.randomUUID(), subject: "Customization Options", duration: 60 },
    { id: crypto.randomUUID(), subject: "Troubleshooting Common Issues", duration: 90 },
    { id: crypto.randomUUID(), subject: "Integration with Existing Systems", duration: 75 },
    { id: crypto.randomUUID(), subject: "Optimizing Workflow Efficiency", duration: 120 },
    { id: crypto.randomUUID(), subject: "Security Measures and Best Practices", duration: 90 },
    { id: crypto.randomUUID(), subject: "User Training and Onboarding", duration: 60 },
    { id: crypto.randomUUID(), subject: "Feedback and Suggestions Session", duration: 60 },
    { id: crypto.randomUUID(), subject: "Case Study Presentation", duration: 45 },
    { id: crypto.randomUUID(), subject: "Data Migration Strategies", duration: 90 },
    { id: crypto.randomUUID(), subject: "Performance Optimization Techniques", duration: 75 },
    { id: crypto.randomUUID(), subject: "Advanced Reporting Options", duration: 90 },
    { id: crypto.randomUUID(), subject: "Upcoming Roadmap Discussion", duration: 45 },
    { id: crypto.randomUUID(), subject: "Cost-Benefit Analysis Review", duration: 90 },
    { id: crypto.randomUUID(), subject: "Compliance and Regulatory Updates", duration: 60 },
    { id: crypto.randomUUID(), subject: "Emerging Technology Integration", duration: 75 },
    { id: crypto.randomUUID(), subject: "Remote Work Support Features", duration: 60 },
    { id: crypto.randomUUID(), subject: "AI and Machine Learning Applications", duration: 90 },
    { id: crypto.randomUUID(), subject: "Data Privacy and Confidentiality Measures", duration: 75 },
    { id: crypto.randomUUID(), subject: "API Documentation and Usage Guidelines", duration: 90 },
    { id: crypto.randomUUID(), subject: "Quality Assurance and Testing Processes", duration: 75 },
    { id: crypto.randomUUID(), subject: "Cost Optimization Strategies", duration: 60 },
    { id: crypto.randomUUID(), subject: "Product Demos and Hands-On Sessions", duration: 60 },
    { id: crypto.randomUUID(), subject: "Scaling for Growth and Expansion", duration: 120 },
    { id: crypto.randomUUID(), subject: "Legacy System Migration Planning", duration: 90 },
    { id: crypto.randomUUID(), subject: "Continuous Improvement Initiatives", duration: 75 },
    { id: crypto.randomUUID(), subject: "Risk Management Strategies", duration: 90 },
    { id: crypto.randomUUID(), subject: "User Experience Enhancement Ideas", duration: 60 },
    { id: crypto.randomUUID(), subject: "Customer Success Story Showcase", duration: 45 },
    { id: crypto.randomUUID(), subject: "Sprint Planning and Review", duration: 90 },
    { id: crypto.randomUUID(), subject: "Innovation Lab Collaboration Opportunities", duration: 120 },
    { id: crypto.randomUUID(), subject: "Performance Monitoring and Analytics", duration: 75 },
    { id: crypto.randomUUID(), subject: "Disaster Recovery Planning", duration: 90 },
    { id: crypto.randomUUID(), subject: "Feature Prioritization Workshop", duration: 120 },
    { id: crypto.randomUUID(), subject: "Product Usability Testing", duration: 90 },
    { id: crypto.randomUUID(), subject: "Technical Debt Reduction Strategies", duration: 75 },
    { id: crypto.randomUUID(), subject: "Budget Planning for Software Upgrades", duration: 90 },
    { id: crypto.randomUUID(), subject: "Customization Showcase", duration: 60 },
    { id: crypto.randomUUID(), subject: "Industry Trends and Benchmarking", duration: 75 },
    { id: crypto.randomUUID(), subject: "Agile Methodology Deep Dive", duration: 90 },
    { id: crypto.randomUUID(), subject: "AI-driven Predictive Analytics Demo", duration: 90 },
    { id: crypto.randomUUID(), subject: "Productivity Hacks for Users", duration: 60 },
    { id: crypto.randomUUID(), subject: "Feedback Implementation Discussion", duration: 90 },
    { id: crypto.randomUUID(), subject: "Migration to Cloud Infrastructure", duration: 120 },
    { id: crypto.randomUUID(), subject: "Platform Integration Strategy", duration: 90 },
    { id: crypto.randomUUID(), subject: "Customer Support Enhancement Ideas", duration: 60 },
    { id: crypto.randomUUID(), subject: "Performance Review and KPIs Alignment", duration: 90 },
    { id: crypto.randomUUID(), subject: "Security Audit Preparation", duration: 120 },
    { id: crypto.randomUUID(), subject: "Product Roadmap Alignment", duration: 75 },
    { id: crypto.randomUUID(), subject: "Workflow Automation Opportunities", duration: 90 },
    { id: crypto.randomUUID(), subject: "Community Engagement Strategies", duration: 60 },
    { id: crypto.randomUUID(), subject: "Introduction to Basic Features", duration: 30 },
    { id: crypto.randomUUID(), subject: "Quick Q&A Session", duration: 30 },
    { id: crypto.randomUUID(), subject: "Demo of New Interface", duration: 30 },
    { id: crypto.randomUUID(), subject: "Discussion on Support Options", duration: 30 },
    { id: crypto.randomUUID(), subject: "Overview of Pricing Plans", duration: 30 },
    { id: crypto.randomUUID(), subject: "Exploration of Data Import Tools", duration: 30 },
];

export function getRandomArraySubset(a, minElements, maxElements) {
    let j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    const num = Math.floor(minElements + Math.random() * (Math.min(maxElements, a.length) - minElements));
    return a.slice(0, Math.max(1, num));
}

function shuffleArray(arr) {
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
}

function getRandomSubsets(arr, numSubsets, minElements, maxElements) {
    const shuffledArr = shuffleArray([...arr]);
    const subsets = [];

    let remainingElements = shuffledArr.length;
    for (let i = 0; i < numSubsets; i++) {
        const numElements = Math.min(maxElements, remainingElements, Math.floor(Math.random() * (maxElements - minElements + 1)) + minElements);

        const subset = shuffledArr.splice(0, numElements);
        remainingElements -= numElements;

        subsets.push(subset);
    }

    return subsets;
}

const USER_GROUPS = {
    "set1": [5, 16, 7, 33, 28, 14, 3, 34, 1],   // Ulita Girindha Anthony Fatma Brooke Antero Jayden Necati Eléonore
    "set2": [23, 4, 22, 2, 15, 32, 35, 25, 12, 31], // Zoe Tina Eli Jacob Maeva Leonel Emilia Madhav Dan Johan
    "set3": [27, 6, 8, 30], // Galina Gabriel Eddie Franklin
    "set4": [9, 23, 31, 11, 20], //Laura, Zoe, Johan, Yolanda, Mia
    "set5": [9, 11, 10, 36, 13, 19, 0], // Laura Yolanda Felix Jonathan Florence Perry Rafael,
    "empty": []
}

export function createDummyPeople(roomIds, minPeople = 0, maxPeople = 1000, deterministicConfig = {}) {
    let participants = {};
    if (Object.keys(deterministicConfig).length > 0) {
        for (const [roomId, config] of Object.entries(deterministicConfig)) {
            participants[roomId] = USER_GROUPS[config.userGroup].map(i => dummyPeople[i]);
        }
        return participants;
    }
    const subsets = getRandomSubsets(dummyPeople, roomIds.length, minPeople, Math.min(maxPeople, dummyPeople.length));
    for (let i = 0; i < roomIds.length; ++i) {
        participants[roomIds[i]] = subsets[i];
    }
    return participants;
}

export function createDummyMeetings(minMeetings = 3, maxMeetings = 1000) {
    return getRandomArraySubset(meetings, minMeetings, maxMeetings);
}
