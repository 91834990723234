<template>
    <div>
        <b-navbar ref="navbar" :fixed-top="true" :centered="isHomePage" :transparent="true">
            <template #brand>
                <div :style="navbarMarginLeftStyle" />
                <b-navbar-item tag="router-link" :to="{ path: brandPath }">
                    <img src="@/assets/hr_logo96.png" alt="Make home to office">
                </b-navbar-item>
                <b-navbar-item v-if="isOfficeSpaceView || isAnalyticsView || isAdminCenterView"
                    style="margin-left: -13px;">
                    <b-dropdown aria-role="list">
                        <template #trigger="{ active }">
                            <b-field grouped>
                                <b-field class="is-flex is-align-items-center" style="margin-left: -10px">
                                    <div class="title is-6">{{ viewName }}</div>
                                </b-field>
                                <b-field class="is-flex is-align-items-center align-content-center">
                                    <b-icon :icon="active ? 'chevron-up' : 'chevron-down'" />
                                </b-field>
                            </b-field>
                        </template>
                        <b-dropdown-item v-if="!isOfficeSpaceView" has-link aria-role=" menuitem">
                            <a :href="getBrandPath('office', true)">
                                <!--b-icon icon="laptop-house"></b-icon-->
                                Office
                            </a>
                        </b-dropdown-item>
                        <b-dropdown-item v-if="!isAnalyticsView" has-link aria-role="menuitem">
                            <a :href="getBrandPath('analytics', true)">
                                <!--b-icon icon="chart-line"></b-icon-->
                                Analytics
                            </a>
                        </b-dropdown-item>
                        <b-dropdown-item v-if="!isAdminCenterView" has-link aria-role="menuitem">
                            <a :href="getBrandPath('admincenter', true)">
                                <!--b-icon icon="user-shield"></b-icon-->
                                AdminCenter
                            </a>
                        </b-dropdown-item>
                    </b-dropdown>
                </b-navbar-item>
            </template>
            <template #start>
                <b-navbar-item v-if="isOfficeSpaceView">
                    <breadcrumb />
                </b-navbar-item>
                <b-navbar-item v-if="isHomePage" tag="router-link" :to="{ path: 'init' }">{{
                    $t("experienceNow")
                    }}</b-navbar-item>
                <b-navbar-item v-if="isHomePage" tag="router-link" :to="{ path: 'pricing' }">{{
                    $t("pricing.pricing")
                    }}</b-navbar-item>
                <b-navbar-item v-if="isHomePage" tag="router-link" :to="{ path: 'about' }">{{ $t("aboutUs")
                    }}</b-navbar-item>
            </template>

            <template #end>
                <b-navbar-item v-if="isOfficeSpaceView && defaultOfficeSpace.tid && !isDefaultOfficeSpace">
                    <span class="icon-text custom-hover-primary"
                        v-on:click="$emit('navigateToOfficeSpace', defaultOfficeSpace)">
                        <span class="icon">
                            <i>
                                <b-icon icon="home" class="is-clickable" />
                            </i>
                        </span>
                    </span>
                </b-navbar-item>
                <b-navbar-item v-if="isOfficeSpaceView">
                    <b-autocomplete :placeholder="$t('placeholders.goToOfficeSpace')" field="name"
                        v-model="officeSpaceSearchInput" @select="officeSpaceSelected" :data="filteredOfficeSpaces"
                        :loading="fetchingOfficeSpaces" icon="walking" :clearable="true" autocomplete="nope"
                        :clear-on-select="false">
                        <template slot-scope="props">
                            <div class="custom-hover-primary">
                                {{ props.option.name }}
                            </div>
                        </template>
                    </b-autocomplete>
                </b-navbar-item>
                <b-navbar-dropdown v-if="!isHomePage && !isMobile" :right="true" arrowless boxed>
                    <template #label>
                        <b-icon icon="cog" size="is-medium"></b-icon>
                    </template>
                    <b-navbar-item>
                        <language-selector />
                    </b-navbar-item>
                    <b-navbar-item>
                        <b-switch v-model="isDarkMode" v-on:input="setDarkModeWithCookie">Dark mode</b-switch>
                    </b-navbar-item>
                </b-navbar-dropdown>
                <b-navbar-item v-if="isHomePage">
                    <language-selector />
                </b-navbar-item>
                <div :style="navbarMarginRightStyle" />
            </template>
        </b-navbar>
    </div>
</template>

<script>
import LanguageSelector from '@/components/LanguageSelector.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
    props: {
        isHomePage: {
            type: Boolean,
            required: true
        }
    },

    components: {
        "breadcrumb": Breadcrumb,
        "language-selector": LanguageSelector
    },

    data() {
        return {
            officeSpaceSearchInput: ""
        }
    },

    computed: {
        brandPath() {
            if (!this.$store.state.me?.tid || (this.$store.state.hybRoomsClient != this.$CONSTANTS.MS_TEAMS_CLIENT_NAME && this.$store.state.hybRoomsClient != this.$CONSTANTS.ZOOM_CLIENT_NAME))
                return "/";
            // if (this.defaultOfficeSpace)
            //     return `/office/${this.defaultOfficeSpace.tid}/${this.defaultOfficeSpace.id}`;
            if (this.isOfficeSpaceView)
                return this.getBrandPath("office");
            if (this.isAnalyticsView)
                return this.getBrandPath("analytics");
            if (this.isAdminCenterView)
                return this.getBrandPath("admincenter");
            return "/";
        },

        viewName() {
            if (this.isOfficeSpaceView)
                return "office";
            if (this.isAnalyticsView)
                return "analytics";
            if (this.isAdminCenterView)
                return "admin center";
            return "default";
        },

        isDarkMode: {
            get() {
                return this.$store.state.darkMode;
            },
            set(value) {
                return;
            }
        },

        isMobile() {
            return this.$store.state.windowWidth < this.$store.state.BREAKPOINTS.tablet;
        },

        isOfficeSpaceView() {
            return this.$route.name == "officeSpace";
        },

        isAnalyticsView() {
            return this.$route.name == "analytics";
        },

        isAdminCenterView() {
            return this.$route.name == "admincenter";
        },

        officeSpaces() {
            return this.$store.state.officeSpaces;
        },

        filteredOfficeSpaces() {
            return this.officeSpaces.filter(o => {
                return o.name ? (o.id != this.viewedOfficeSpaceId || o.tid != this.viewTenantId) && o.name.toLowerCase().indexOf(this.officeSpaceSearchInput.toLowerCase()) >= 0 : false
            });
        },

        fetchingOfficeSpaces() {
            return this.$store.state.fetchingOfficeSpaces;
        },

        viewTenantId() {
            return this.$store.state.viewTenantId;
        },

        viewedOfficeSpaceId() {
            return this.$store.state.viewedOfficeSpaceId;
        },

        currentOfficeSpace() {
            return this.officeSpaces.find(o => o.id == this.viewedOfficeSpaceId && o.tid == this.viewTenantId);
        },

        defaultOfficeSpace() {
            return this.$store.state.defaultOfficeSpace;
        },

        isDefaultOfficeSpace() {
            if (this.defaultOfficeSpace && this.currentOfficeSpace && this.currentOfficeSpace.tid == this.defaultOfficeSpace.tid && this.currentOfficeSpace.id == this.defaultOfficeSpace.id)
                return true;
            return false;
        },

        isTablet() {
            return this.$store.state.windowWidth < this.$store.state.BREAKPOINTS.desktop;
        },

        isExternalClient() {
            return [this.$CONSTANTS.MS_TEAMS_CLIENT_NAME, this.$CONSTANTS.ZOOM_CLIENT_NAME].includes(this.$store.state.hybRoomsClient);
        },

        navbarMarginLeftStyle() {
            const margin = this.isExternalClient ? 0 : Math.max((this.$store.state.windowWidth - this.$store.state.BREAKPOINTS.fullhd) * 0.5, 0);
            return `margin-left: ${margin}px;`
        },

        navbarMarginRightStyle() {
            const margin = this.isExternalClient ? 0 : Math.max((this.$store.state.windowWidth - this.$store.state.BREAKPOINTS.fullhd) * 0.5, 0);
            return `margin-right: ${margin}px;`
        },

        locationHierarchy() {
            let hierarchy = [{
                name: "HybRooms",
                link: encodeURI(`/office/${this.viewTenantId}`)
            }]
            if (this.currentOfficeSpace)
                hierarchy.push({
                    name: this.currentOfficeSpace.name,
                    link: encodeURI(`/office/${this.viewTenantId}/${this.currentOfficeSpace.id}`),
                    isDefaultOfficeSpace: this.isDefaultOfficeSpace
                })
            return hierarchy;
        },

        /*navbarStartStyle() {
            if (this.isOfficeSpaceView)
                return ""
            const brandElement = document.getElementsByClassName("navbar-brand")[0];
            const endElement = document.getElementsByClassName("navbar-end")[0];
            if (!brandElement || !endElement)
                return ""
            const brandWidth = brandElement.getBoundingClientRect().width;
            const endWidth = endElement.getBoundingClientRect().width;
            return `margin-left: ${(endWidth - brandWidth) * 0.5}px;`
        }*/
    },

    methods: {
        officeSpaceSelected(option) {
            // When the autucomplete is cleared, it is called with an empty option.
            // This early exit is a workaround for that
            if (!option)
                return;
            this.$emit("navigateToOfficeSpace", option);
        },

        getBrandPath(context, addHash = false) {
            let prefix = addHash ? '/#' : ''
            if (context == "office")
                return prefix + `/office/${this.$store.state.me.tid}`;
            if (context == "analytics")
                return prefix + "/analytics";
            if (context == "admincenter")
                return prefix + "/admincenter";
            return prefix + "/";
        },

        createOfficeSpace(event) {
            this.$emit("createOfficeSpace", event);
        },

        createRoom(event) {
            this.$emit("createRoom", event);
        },

        setDarkModeWithCookie(value) {
            if (this.$store.state.systemDarkMode === value)
                this.$deleteCookie("darkMode");
            else
                this.$setCookie("darkMode", value, 365);
            this.$setDarkMode(value);
        },
    }
}
</script>

<style scoped>
.field.routerItemWithPin .field:not(:last-child) {
    margin-right: 0.4rem;
}
</style>
