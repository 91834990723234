<template>
    <section>
        <div class="container is-widescreen">
            <div v-if="displayedPeople.length == 1"
                class="mockCallPersonContainer rounded-borders is-flex is-justify-content-center">
                <div style="width: 156px; height: 156x;">
                    <b-field class="is-flex is-justify-content-center">
                        <person :user="displayedPeople[0]" :enableHover="false" :size="128" />
                    </b-field>
                    <b-field class="is-flex is-justify-content-center has-text-white">
                        {{ displayedPeople[0].displayName }}
                    </b-field>
                </div>
            </div>
            <div v-else class="columns is-multiline is-centered">
                <div v-for="(person, index) in displayedPeople" :key="index" :class="gridClass"
                    class="column is-narrow">
                    <div class="mockCallPersonContainer rounded-borders is-flex is-justify-content-center"
                        :class="speakingClass[index]">
                        <div style="width: 144px; height: 144px;">
                            <b-field class="is-flex is-justify-content-center">
                                <person :user="person" :enableHover="false" :key="person.tid + person.id" :size="128" />
                            </b-field>
                            <b-field class="is-flex is-justify-content-center has-text-white">
                                {{ person.displayName }}
                            </b-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="is-flex is-justify-content-center mt-6">
                <b-field grouped>
                    <b-field>
                        <div class="icon-circle">
                            <b-icon icon="microphone" class="is-large is-clickable"></b-icon>
                        </div>
                    </b-field>
                    <b-field>
                        <div class="icon-circle">
                            <b-icon icon="video-slash" class="is-large is-clickable"></b-icon>
                        </div>
                    </b-field>
                    <b-field>
                        <div class="icon-circle danger" v-on:click="$emit('close')">
                            <b-icon icon="phone-alt" class="is-clickable is-large"></b-icon>
                        </div>
                    </b-field>
                </b-field>
            </div>
        </div>
    </section>
</template>

<script>
import Person from "@/components/Person.vue";

export default {
    props: {
        room: {
            type: Object,
            required: true
        }
    },
    components: {
        person: Person
    },
    data() {
        return { speakingClass: new Array(256).fill("") }
    },
    computed: {
        displayedPeople() {
            const existing = this.$store.state.participants?.[this.room.tid]?.[this.room.officeSpaceId]?.[this.room.id] || [];
            const people = [this.$store.state.me].concat(existing);
            return people.slice(0, 16);
        },
        gridClass() {
            const count = this.displayedPeople.length;
            if (count <= 4) return 'is-half';
            else if (count >= 5 && count <= 8) return 'is-one-third';
            else if (count >= 9) return 'is-one-fourth';
            return 'is-full'; // for safety
        },
    },
    mounted() {
        this.setSpeaker();
        if (this.speakingClass.length > 1) {
            this.speakingClass[0] = "hasBorderBlue";
            setTimeout(() => this.speakingClass[0] = "", 750);
        }
    },
    methods: {
        setSpeaker() {
            this.speakingClass = new Array(this.displayedPeople.length).fill("");
            if (this.speakingClass.length > 1) {
                const time = 1500 + Math.floor(Math.random() * 500);
                const next_speaker = Math.min(this.speakingClass.length - 1, 1 + Math.floor(Math.random() * this.speakingClass.length));
                this.speakingClass[next_speaker] = "hasBorderBlue";
                setTimeout(() => this.setSpeaker(), time);
            }
        }
    },
    watch: {
        displayedPeople(newValue, oldValue) {
            if (oldValue.length == 1 && newValue.length > 1) {
                this.setSpeaker();
            }
        }
    }
}
</script>

<style>
.icon-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f0f0f0;
    margin: 5px;
}

.icon-circle:hover {
    background-color: #2196f3;
    color: white;
}

.icon-circle.black:hover {
    background-color: #363636;
    color: white;
}

.icon-circle.danger {
    color: #f14668;
}

.icon-circle.danger:hover {
    background-color: #f14668;
    color: white;
}

.mockCallPersonContainer {
    border: 5px solid #060606;
    background-color: #363636;
    /* Border thickness, style, and color */
    padding: 24px;
}

.mockCallPersonContainer.hasBorderBlue {
    border: 5px solid #2196f3;
}
</style>